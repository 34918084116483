@mixin navbar-theme($theme) {

  navbar {
    background: map-get($panel-color, 500);
    .navbar-top {
      .spl-red-color {
        color: map-get($main-theme-color, 500);
      }
    }
  }
}
