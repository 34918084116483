@mixin toolbar-theme($theme) {
  $foreground: map-get($theme, foreground);

  toolbar {
    border-bottom: 1px solid map-get($border-color, 500);

    .toolbar-separator {
      background: map-get($foreground, divider);
    }

    mat-icon {
      color: map-get($main-theme-color, 500);
    }

    .mat-toolbar {
      &.isMobile {
        background: map-get($mobile-header, 500) !important;
      }
    }
  
    .mobile-header-wrapper {
    
      .back-button {
        color: map-get($main-theme-color, 500);
      }
    
      .breadcrumb {
        color: map-get($title-color, 500);
      }
    }
  }
}
